import React, { useState, useEffect } from "react";
import axios from "axios";
import { Image, CloudinaryContext } from "cloudinary-react";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

import "./Gallery.scss";

const tag = "theoberndt";
const url = `https://res.cloudinary.com/oridnary/image/list/${tag}.json`;

const randomArray = (currentArray) => {
  const mixedArray = [...currentArray];
  for (let i = mixedArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = mixedArray[i];
    mixedArray[i] = mixedArray[j];
    mixedArray[j] = temp;
  }
  return mixedArray;
};

const Gallery = () => {
  const [images, setImages] = useState([]);

  const settings = {
    dots: false,
    arrows: false,
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
    draggable: false,
    fade: true,
    lazyLoad: true,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    // Get images by tag
    axios.get(url).then((res) => {
      const fetchedImages = res?.data?.resources ? res.data.resources : null;

      if (fetchedImages.length > 0) {
        setImages(fetchedImages);
      }
    });
  }, []);

  const randomImages = images?.length > 0 ? randomArray(images) : [];

  return (
    <CloudinaryContext cloudName="oridnary">
      <Slider {...settings}>
        {randomImages?.length > 0 &&
          randomImages.map((image) => {
            const imageId = image.public_id;

            return (
              <Image
                // onLoad={onLoad}
                // loading="lazy"
                className={"image"}
                key={imageId}
                publicId={imageId}
                dpr="auto"
                responsive
                width="auto"
                crop="scale"
                responsiveUseBreakpoints="true"
                fetchFormat="auto"
                quality="auto"
              />
            );
          })}
      </Slider>
    </CloudinaryContext>
  );
};

export default Gallery;
