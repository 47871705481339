import React from "react";
import ReactDOM from "react-dom";

import SEO from "./src/components/SEO/SEO";
import Gallery from "./src/components/Gallery/Gallery";
import Logotype from "./src/components/Logotype/Logotype";

import "./assets/styles/app.scss";

ReactDOM.render(
  <div className="wrapper">
    <SEO />
    <Gallery />
    <Logotype />
  </div>,
  document.getElementById("root")
);
