import React from "react";
import { Helmet } from "react-helmet";

const title = "Theo Berndt";
const description = "";
const type = "website";
const url = "https://www.theoeberndt.com";
const image = "";

const SEO = () => (
  <Helmet>
    <html lang="en" />
    <meta charSet="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1"
    />
    {title && <title>{title}</title>}
    {title && <meta property="og:title" content={title} />}
    {title && <meta property="twitter:title" content={title} />}
    {title && <meta property="og:image:alt" content={title} />}
    {title && <meta property="twitter:image:alt" content={title} />}

    {description && <meta name="description" content={description} />}
    {description && <meta name="og:description" content={description} />}

    {image && <meta property="og:image" content={image} />}
    {image && <meta property="twitter:image:src" content={image} />}

    {type && <meta property="og:type" content={type} />}
    {type && <meta property="twitter:type" content={type} />}

    {url && <meta property="og:url" content={url} />}
    {url && <meta property="twitter:url" content={url} />}
  </Helmet>
);

export default SEO;
